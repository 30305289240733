<template>
  <div class="page">
    <Tables
      ref="xTable"
      :tableName="tableName"
      :tableData="tableData"
      :totalPage="totalPage"
      :isLoading="isLoading"
      :searchFormData="searchFormData"
      :showRefresh="true"
      :showSearch="true"
      @getList="getList"
      @clearSearch="clearSearch"
    >
      <!--搜索自定义内容-->
      <el-form-item slot="search-item">
        <el-input
          v-model="searchFormData.refund_no"
          size="small"
          clearable
          placeholder="按退款号查询"
        ></el-input>
      </el-form-item>
      <el-form-item slot="search-item">
        <el-input
          v-model="searchFormData.order_no"
          size="small"
          clearable
          placeholder="按订单号查询"
        ></el-input>
      </el-form-item>
      <el-form-item slot="search-item">
        <el-select
          v-model="searchFormData.refund_type"
          placeholder="按退款类型查询"
          clearable
          size="small"
        >
          <el-option
            label="消费订单"
            :value="10"
          >
          </el-option>
          <el-option
            label="充值"
            :value="20"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <!--表格内容-->
      <vxe-table-column
        slot="table-item"
        field="refund_no"
        title="退款号"
        align="center"
        min-width="100px"
      />
      <vxe-table-column
        slot="table-item"
        field="order_no"
        title="订单号"
        align="center"
        min-width="100px"
      />
      <vxe-table-column
        slot="table-item"
        field="refund_price"
        title="退款金额"
        align="center"
        min-width="80px"
      />
      <vxe-table-column
        slot="table-item"
        field="order_price"
        title="订单金额"
        align="center"
        min-width="80px"
      />
      <vxe-table-column
        slot="table-item"
        field="unusual_status"
        title="退款原因"
        align="center"
        min-width="80px"
      >
        <template v-slot="{ row }">
          <span>{{types.unusual_status[row.unusual_status]||'未知原因'}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column
        slot="table-item"
        field="refund_type"
        title="退款类型"
        align="center"
        min-width="80px"
      >
        <template v-slot="{ row }">
          <span>{{types.refund_type[row.refund_type]||'未知类型'}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column
        slot="table-item"
        field="refund_model"
        title="退款模式"
        align="center"
        min-width="80px"
      >
        <template v-slot="{ row }">
          <span>{{types.refund_model[row.refund_model]||'未知模式'}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column
        slot="table-item"
        field="refund_for"
        title="回退地方"
        align="center"
        min-width="80px"
      >
        <template v-slot="{ row }">
          <span>{{types.refund_for[row.refund_for]||'未知地方'}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column
        slot="table-item"
        field="refund_time"
        title="退款时间"
        align="center"
        min-width="100px"
      />
    </Tables>
  </div>
</template>

<script>
import Tables from '@/components/tables'
export default {
  name: 'user-refund-list',
  components: {
    Tables
  },
  data () {
    return {
      // 表格信息
      tableName: '退款列表',
      isLoading: false,
      tableData: [],
      totalPage: 0,
      searchFormData: {
        u_type: 10
      },
      types: {
        unusual_status: {
          10: '其他异常',
          20: '功率异常',
          30: '插座脱落',
          40: '信号异常',
          50: '用户退款'
        },
        refund_for: {
          10: '钱包',
          20: '电卡',
          30: '现金',
          40: '赠送金'
        },
        refund_type: {
          10: '消费订单',
          20: '充值'
        },
        refund_model: {
          10: '自动',
          20: '系统操作'
        }
      }
    }
  },
  methods: {
    // 表格列表
    async getList (currentPage, pageSize) {
      const params = {
        token: this.$store.state.user.token,
        page: currentPage,
        size: pageSize
      }
      this.isLoading = true
      const searchData = this.searchFormData
      const res = await this.$api.Fans.GetRefundList(params, searchData)
      this.tableData = res.data
      this.totalPage = res.total
      this.isLoading = false
    },
    // 重置搜索
    clearSearch () {
      this.searchFormData = {
        u_type: 10
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
